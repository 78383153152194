
import { computed, defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Field, ErrorMessage, useForm } from "vee-validate";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import * as Yup from "yup";
import { array } from "yup";

interface DonorCreateData
  extends DonorTypeData,
    DonorDetailsData,
    DonorAddressData {}

interface DonorTypeData {
  donorType: string;
}

interface DonorDetailsData {
  name: string;
  surname: string;
  birthdate: string;
}

interface DonorAddressData {
  street: string;
  streetNumber: string;
  streetBetween1: string;
  streetBetween2: string;
  state: string;
  district: string;
  geolocationResult: string;
}

interface Anything {
  [key: string]: any;
}

interface GeocodedAddress {
  home: string;
  postalCode: string;
  street: string;
  region: string;
  city: string;
  country: string;
}

export default defineComponent({
  name: "donor-modal",
  components: {
    Field,
    ErrorMessage,
  },

  data() {
    return {
      donorform: {
        endpoint: "donor",
        entityName: "Donante",
        steps: [
          {
            name: "Datos Basicos",
            fields: [
              {
                name: "firstname",
                label: "Nombre",
              },
              {
                name: "surname",
                label: "Last Name",
              },
            ],
          },
        ],
      },
    };
  },

  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createDonorRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const formData = ref<DonorCreateData>({
      donorType: "hcg",
      name: "Pepe",
      surname: "Lopez",
      birthdate: "03/02/1987",
      street: "Miguel de Salcedo",
      streetNumber: "2647",
      streetBetween1: "España",
      streetBetween2: "Italia",
      state: "Buenos Aires",
      district: "Castelar",
      geolocationResult: "-1",
    });

    const createDonorSchema = [
      Yup.object({}),
      Yup.object({
        donorType: Yup.string().required().label("Tipo de Donante"),
      }),
      Yup.object({
        name: Yup.string().required().label("Nombre"),
        surname: Yup.string().required().label("Apellido"),
      }),
    ];

    onMounted(async () => {
      _stepperObj.value = StepperComponent.createInsance(
        createDonorRef.value as HTMLElement
      );
    });

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createDonorSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<
      DonorTypeData | DonorDetailsData | DonorAddressData
    >({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const GeoResult = ref<Anything[]>([]);

    const getAddressObject = (addressComponents) => {
      const ShouldBeComponent = {
        home: ["street_number"],
        postalCode: ["postal_code"],
        street: ["street_address", "route"],
        region: [
          "administrative_area_level_1",
          "administrative_area_level_2",
          "administrative_area_level_3",
          "administrative_area_level_4",
          "administrative_area_level_5",
        ],
        city: [
          "locality",
          "sublocality",
          "sublocality_level_1",
          "sublocality_level_2",
          "sublocality_level_3",
          "sublocality_level_4",
        ],
        country: ["country"],
      };

      const address = {
        home: "",
        postalCode: "",
        street: "",
        region: "",
        city: "",
        country: "",
      };
      addressComponents.forEach((component) => {
        for (const shouldBe in ShouldBeComponent) {
          if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
            if (shouldBe === "country") {
              address[shouldBe] = component.short_name;
            } else {
              address[shouldBe] = component.long_name;
            }
          }
        }
      });
      return address;
    };

    const handleStep = handleSubmit((values) => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }
      const parsedResults: GeocodedAddress[] = [];

      if (currentStepIndex.value == 2) {
        GeoResult.value = [];
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const geocoding = require("geocoding");
        const addressString =
          formData.value["street"] +
          " " +
          formData.value["streetNumber"] +
          " " +
          formData.value["district"] +
          " " +
          formData.value["state"];
        geocoding({
          key: "AIzaSyBseSpX_-WHP569pW1gO7PZWTK5O8jc4FI",
          address: addressString,
          components: {
            country: "AR",
          },
        })
          .then(function (results) {
            results.map((result) => {
              parsedResults.push(getAddressObject(result.address_components));
            });
            GeoResult.value = parsedResults;
          })
          .catch(function (err) {
            // TODO: Error Handling
          });
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const formSubmit = () => {
      console.log(formData.value);

      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        window.location.reload();
      });
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    return {
      createDonorRef,
      totalSteps,
      previousStep,
      handleStep,
      GeoResult,
      formSubmit,
      getAddressObject,
      currentStepIndex,
      formData,
    };
  },
});
